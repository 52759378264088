<template>
  <div>
      <b-form>
            <h3 class="text-left">Anmeldedaten</h3>
    <!--User Email-->
                <b-form-row class="justify-content-center">
                    <b-col cols="12">
                        <b-form-group>
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="Email eingeben"
                                required
                                :state="validateMail"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
    <!--Password-->
                    <b-col cols="12">
                        <b-form-group>
                            <b-form-input
                                v-model="form.password"
                                type="password"
                                placeholder="Passwort eingeben"
                                required
                                :state="validatePassword"
                            ></b-form-input>
                        </b-form-group>          
                    </b-col>
    <!--Password repeat-->
                    <b-col cols="12">
                        <b-form-group>
                            <b-form-input
                                v-model="form.passwordRepeat"
                                type="password"
                                placeholder="Passwort wiederholen"
                                required
                                :state="validatePasswordRepeat"
                            ></b-form-input>
                        <small v-if="!validatePassword">Passwort muss mindestens 1 Zahl, einen Groß- und Kleinbuchstaben enthalten, sowie mindestens 8 Zeichen lang sein.</small>
                        <small v-if="!validatePasswordRepeat">Passwörter müssen übereinstimmen!</small>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            <h3 class="text-left">Personendaten</h3>
    <!--User First Name-->
                <b-form-row class="justify-content-center">
                    <b-col cols="12" md="6">
                        <b-form-group>
                            <b-form-input
                                v-model="form.firstName"
                                type="text"
                                placeholder="Vorname"
                                required
                                :state="validateFirstName"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
    <!--User Surname-->
                    <b-col cols="12" md="6">
                        <b-form-group>
                            <b-form-input
                                v-model="form.surname"
                                type="text"
                                placeholder="Nachname"
                                required
                                :state="validateSurname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            <b-form-row class="justify-content-center">
    <!--User Phone-->
                    <b-col cols="12">
                        <b-form-group>
                            <b-form-input
                                v-model="form.phone"
                                type="text"
                                placeholder="Telefon"
                                required
                                :state="validatePhone"
                            ></b-form-input>
                        <small v-if="!validatePhone">Bitte nur Zahlen eingeben!</small>
                        </b-form-group>
                    </b-col>
            </b-form-row>
            </b-form>
    <!--Submit Button-->
        <b-row align-h="end">
            <b-col cols="auto">
                <b-button
                class="submit" 
                type="button" 
                :variant="toggleButtonVariant" 
                @click="submit"
                :disabled="!validateForm"
                >
                Registrieren
                </b-button>
            </b-col>
        </b-row>
  </div>
</template>

<script>
export default {
    data: () => ({
        form: {
            email: "",
            password: "",
            passwordRepeat: "",
            firstName: "",
            surname: "",
            phone: "",
        }
    }),
    computed: {
        validateMail(){
            return this.form.email.includes('@')
        },
        validatePassword(){
            const regexNum = /\d/g;
            const regexUpper = /[a-z]/g;
            const regexLower = /[A-Z]/g;
            return (
                this.form.password !== "" 
                && regexNum.test(this.form.password) 
                && this.form.password.length > 7 
                && regexUpper.test(this.form.password)
                && regexLower.test(this.form.password)
            )
        },
        validatePasswordRepeat(){
            return this.form.passwordRepeat !== "" && (this.form.password === this.form.passwordRepeat) 
        },
        validateForm(){
            return (
                this.validateMail 
                && this.validatePassword 
                && this.validatePasswordRepeat
                && this.validateFirstName
                && this.validateSurname
                && this.validatePhone
                )
        },
        validateFirstName(){
            return this.form.firstName !== ""
        },
        validateSurname(){
            return this.form.surname !== ""
        },
        validatePhone(){
            let onlyNumber = /^\d+$/;
            return onlyNumber.test(this.form.phone)
        },
        toggleButtonVariant(){
            return this.validateForm ? "success" : "outline-secondary"
        },
    },
    methods: {
        submit: function(){
            console.log(this.form.email)
        },
    }
}
</script>

<style scoped>
    small{
        display: block;
        text-align: left !important;
    }
</style>
