<template>
  <div>
      <b-form>
    <!--User Email-->
                <b-form-row class="justify-content-center">
                    <b-col cols="12" sm="3">
                        <b-form-group>
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="Usernamen/Email eingeben"
                                required
                                :state="validateMail"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
    <!--Password-->
                    <b-col cols="12" sm="3">
                        <b-form-group>
                            <b-form-input
                                v-model="form.password"
                                type="password"
                                placeholder="Passwort eingeben"
                                required
                                :state="validatePassword"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </b-form>
    <!--Submit Button-->
        <b-row align-h="end">
            <b-col cols="auto">
                <b-button
                class="submit" 
                type="button" 
                :variant="toggleButtonVariant" 
                @click="submit"
                :disabled="!validateForm"
                >
                Einloggen
                </b-button>
            </b-col>
        </b-row>
    <!--PW Reset
        <b-row align-h="start">
            <b-col cols="auto">
                <b-button size="sm" variant="light">Passwort vergessen? Hier klicken und zurücksetzen...</b-button>
            </b-col>
        </b-row>
    -->
    <span v-if="success"> Hat geklappt! Weiterleitung...</span>
    <span v-if="error"> Da ist was schiefgelaufen... </span>
  </div>
</template>

<script>
import data from '../../data/data.js'
export default {
data: () => ({
        success: false,
        error: false,
        form: {
            email: "",
            password: "",
        }
    }),
    computed: {
        validateMail(){
            return this.form.email.includes('@')
        },
        validatePassword(){
            return this.form.password !== ""
        },
        validateForm(){
            return this.validateMail && this.form.password != ""
        },
        toggleButtonVariant(){
            return this.validateForm ? "success" : "outline-secondary"
        },
    },
    methods: {
        submit: function(){
            /**
             * 1. Send login information to server
             * 2. Wait for response
             * 3.1. If success:  
             *  this.$store.commit("setLoggedInStatus", true)
             *  this.$router.push("/")
             * 3.2. If failure:
             *  Red text: try again.
             * */
            data.login({
                username: this.form.email,
                password: this.form.password
            })
            .then((res)=>{
                this.error = false
                this.success = true
                this.$store.commit("setLoggedInStatus", true)
                this.$store.commit("setRole", res.data.role)
                this.$router.push("/")
            })
            .catch(()=>{
                this.success = false
                this.error = true
            })

        },
    }
}
</script>

<style scoped>

</style>