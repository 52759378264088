<template>
    <div>
        <h1>Fahrzeug Einstellungen</h1>
        <h2><small>Gesamtanzahl Fahrzeuge aller Standorte: {{numberOfAllFahrzeuge}}</small></h2>
        <br>
        <b-row>
            <b-col cols="12">
                <div v-for="(array, standort) in standorte" :key="standort">
                    <h2 class="standortheading">{{standort}} <b-button @click="openEmpty(standort)" size="sm" variant="outline-success">Hinzufügen <b-icon icon="plus-circle-fill"></b-icon></b-button></h2>
                        <h3><small>Anzahl Fahrzeuge für den Standort: {{numberOfFahrzeuge(array)}}</small></h3>
                        <b-row>
                            <b-col
                                @click="openSelectedFahrzeug(fahrzeug)"
                                class="fahrzeug"
                                cols="4" lg="2" 
                                v-for="(fahrzeug, i) of array" :key="i"
                            >{{fahrzeug.nummer}}
                            </b-col>
                        </b-row>
                </div>
            </b-col>
        </b-row>
        <b-modal id="fahrzeugModal" :busy="true" no-close-on-backdrop @ok="saveFahrzeug" @cancel="deleteFahrzeug" :title="fahrzeugIsSelected ? 'Fahrzeug ändern' : 'Fahrzeug hinzufügen'">
                <span>
                    <h3>{{selectedStandort}}</h3>
                    <br>
                    <div v-if="fahrzeugIsSelected">
                        <label for="fahrzeug-aendern">Nummer</label>
                        <b-form-input id="fahrzeug-aendern" v-model="selectedFahrzeugNummer" :value="selectedFahrzeug.nummer"></b-form-input>
                        <br>
                        <b-form-checkbox v-model="isDeleteable">Ich möchte das Fahrzeug löschen</b-form-checkbox>
                    </div>
                    <div v-else>
                        <label for="fahrzeug-hinzu">Fahrzeugnummern</label>
                        <b-form-textarea
                            id="fahrzeug-hinzu"
                            v-model="fahrzeugeToAdd"
                            placeholder="Mehrere Fahrzeuge hinzufügen durch Trennung mit Komma ohne Leerzeichen: Fahrzeug1,Fahrzeug2,..."
                            rows="3"
                        ></b-form-textarea>
                    </div>
                </span>
                <template #modal-footer="{ ok, cancel, hide }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" variant="success" @click="ok()">
                        Speichern
                    </b-button>
                    <b-button v-if="fahrzeugIsSelected" :disabled="!isDeleteable" size="sm" variant="danger" @click="cancel()">
                        Löschen
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button size="sm" variant="outline-secondary" @click="hide()">
                        Abbrechen
                    </b-button>
                </template>
            </b-modal>
        <b-modal id="loadingModal" no-close-on-backdrop @close="resetLoading" hide-footer>
                <b-spinner v-show="isLoading" variant="success" label="Spinning"></b-spinner>
                <br>
                <h3 :class="toggleClasses" v-if="loadingSuccess||loadingFailure">{{loadingText}}</h3>
                <br>
                <b-button class="confirm-button" variant="secondary" @click="resetLoading">Ok</b-button>
        </b-modal>
    </div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
        return {
            standorte: {},
            selectedStandort: '',
            selectedFahrzeug: {},
            selectedFahrzeugNummer: '',
            fahrzeugeToAdd: '',
            isLoading: false,
            loadingSuccess: false,
            loadingFailure: false,
            loadingText: '',
            isDeleteable: false
        }
    },
    methods:{
        /**
         * Executes a PUT or a POST request to save the fahrzeug, dependend on the selectedFahrzeug
         */
        saveFahrzeug: function(){
            this.loading()

            //Execute PUT when a fahrzeug is selected
            if(this.fahrzeugIsSelected){
                data.putOnServer(`fahrzeug/${this.selectedFahrzeug.nummer}`, {nummer: this.selectedFahrzeugNummer})
                    .then(res=>{
                        this.updateEntry(res.data)
                        this.loaded(true)
                    })
                    .catch(err=>{
                        this.loaded(false, err)
                    })
            }else{
                var req = {
                    data: [],
                    standort: this.selectedStandort
                }

                for(let fahrzeug of this.fahrzeugeToAdd.split(',')){
                    req.data.push({nummer: fahrzeug.trim()})
                }
                data.postOnServer('fahrzeug', req)
                    .then(res=>{
                        this.updateEntry(res.data)
                        this.loaded(true)
                    })
                    .catch(err=>{
                        this.loaded(false, err)
                    })
                
            }
        },
        /**
         * Deletes selectedFahrzeug on Server
         */
        deleteFahrzeug: function(){
            this.loading()
            data.deleteOnServer(`fahrzeug/${this.selectedFahrzeug.nummer}`)
                .then(res=>{
                    this.updateEntry(res.data, true)
                    this.loaded(true)
                })
                .catch(err=>{
                    this.loaded(false, err)
                })
        },
        /**
         * Updates an entry or deletes it, depending if a new load is given
         */
        updateEntry: function(load, deleteEntry=false){
            if(this.fahrzeugIsSelected){
                for(var i =0; i < this.standorte[load.standort.name].length; i++){
                    if(this.standorte[load.standort.name][i]._id === load._id){
                        if(deleteEntry){
                            this.standorte[load.standort.name].splice(i, 1)
                        }else{
                           this.standorte[load.standort.name].splice(i, 1, load) 
                        } 
                    }
                }
            }else{
                this.standorte[load[0].standort.name] = this.standorte[load[0].standort.name].concat(load)
            }
        },
        /**
         * Sets selectedFahrzeug to the Fahrzeug thats been clicked on and opens modal
         */
        openSelectedFahrzeug: function(fahrzeug){
            this.selectedFahrzeug = JSON.parse(JSON.stringify(fahrzeug))
            this.selectedFahrzeugNummer = this.selectedFahrzeug.nummer
            this.selectedStandort = fahrzeug.standort.name
            this.isDeleteable = false
            this.$bvModal.show("fahrzeugModal");
        },

        /**
         * Opens empty modal to add multiple fahrzeuge
         */
        openEmpty: function(standort){
            this.selectedFahrzeug = {}
            this.selectedFahrzeugNummer = ''
            this.selectedStandort = standort
            this.fahrzeugeToAdd = ''
            this.$bvModal.show("fahrzeugModal");
        },

        /**
         * Sets loading flags and confirmation text in the data depending on the success of the server request
         */
        loaded: function(successful, error=null){
            this.isLoading = false
            this.loadingSuccess = successful
            this.loadingFailure = !successful
            if(successful){
                this.loadingText = "Erfolgreich geladen und gespeichert!"
            }else{
                if(error){
                    this.loadingText = `Es hat einen Fehler gegeben: \n ${error}`
                }else{
                    this.loadingText = 'Es hat einen unbekannten Fehler gegeben.'
                }
            }
        },

        /**
         * Sets loading flags to loading status
         */
        loading: function(){
            this.isLoading = true
            this.loadingSuccess = false
            this.loadingFailure = false
            this.$bvModal.show('loadingModal')
        },

        /**
         * Resets all loading variables to have a fresh modal after repeatedly open/close a modal 
         */
        resetLoading: function(){
            this.isLoading = false
            this.loadingSuccess = false
            this.loadingFailure = false
            this.loadingText = ''
            this.$bvModal.hide('loadingModal')
        },
    },
    computed:{
        /**
         * Returns if a fahrzeug is currently selected
         */
        fahrzeugIsSelected(){
            return Object.keys(this.selectedFahrzeug).length !== 0
        },
        /**
         * Returns the length of the fahrzeug array of the standort
         */
        numberOfFahrzeuge(){
            return list => list.length
        },
        /**
         * Returns the length of the fahrzeug array of the standort
         */
        numberOfAllFahrzeuge(){
            var res = 0
            for(let k of Object.keys(this.standorte)){
                res += this.standorte[k].length
            }
            return res
        },
    },
    /**
     * Retrives all standorte and fahrzeuge from the server and sorts them into the standort property
     */
    async beforeMount(){
        /**
         * Get all standorte
         */
        await data.getAllFromServer('standort')
            .then((res)=>{
                for(const standort of res.data){
                    this.$set(this.standorte, standort.name, [])
                }
            })

        /**
         * Get all fahrzeuge ands sorts them into the correct rows
         */
        await data.getAllFromServer('fahrzeug')
            .then((res)=>{
                for(const fahrzeug of res.data){
                    this.$set(this.standorte[fahrzeug.standort.name], this.standorte[fahrzeug.standort.name].length, fahrzeug)
                }
            })
    },
}
</script>

<style scoped>

    .fahrzeug:hover{
        color: whitesmoke;
        cursor: pointer;
    }

    .standortheading{
        margin-top: 25px;
    }
</style>