<template>
  <div>
    <b-card no-body>
        <b-tabs card>
<!--Anmelden Tab-->
        <b-tab title="Anmelden" active>
            <h2>Bitte anmelden!</h2>
            <LoginForm/>
        </b-tab>
<!--Registrieren Tab-->
        <b-tab title="Registrieren" disabled>
            <h2>Registrieren</h2>
            <RegisterForm/>
        </b-tab>
        </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LoginForm from "../forms/login-form.vue"
import RegisterForm from "../forms/register-form.vue"
export default {
    components:{
        LoginForm,
        RegisterForm
    }
}
</script>

<style scoped>

</style>