<template>
  <div>
        <h1>Kontakt Einstellungen</h1>
        <b-row>
            <b-col cols="12">
                <div class="standortarea" v-for="(grund, standort) in standorte" :key="standort">
                    <h2 class="standortheading">{{standort}}</h2>
                        <div v-for="(kontakt, grundname) in grund" :key="grundname">
                           <h4 class="grundheading">{{grundname}} <b-button @click="modalEmpty(standort, grundname)" size="sm" variant="outline-success">Hinzufügen <b-icon icon="plus-circle-fill"></b-icon></b-button></h4> 
                            <div class="option-table" @click="openModal(kontaktinfo, grundname, standort)" v-for="kontaktinfo in kontakt" :key="kontaktinfo._id">
                                <b-row>
                                    <b-col class="entry" cols="3">
                                        <span class="first-entry">{{kontaktinfo.name}}</span>
                                    </b-col>
                                    <b-col class="entry" cols="3">
                                        <span class="sec-entry">{{kontaktinfo.mail}}</span>
                                    </b-col>
                                    <b-col class="filler" cols="3"></b-col>
                                </b-row>
                            </div>
                            
                        </div>
                </div>
            </b-col>
            <b-modal id="modal-1" :busy="true" no-close-on-backdrop @ok="modalSave" @cancel="modalDelete" title="Kontaktänderung">
                <span>
                    <h3>{{selectedStandort}}</h3>
                    <br>
                    <h4>Kontaktperson für {{selectedGrund}}</h4>
                    <label for="kontakt-name">Name</label>
                    <b-form-input id="kontakt-name" v-model="selectedName" :value="selectedName" ></b-form-input>
                    <label for="kontakt-mail">Mail</label>
                    <b-form-input id="kontakt-mail" v-model="selectedMail" :value="selectedMail" ></b-form-input>
                </span>
                <template #modal-footer="{ ok, cancel, hide }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" variant="success" @click="ok()">
                        Speichern
                    </b-button>
                    <b-button size="sm" variant="danger" @click="cancel()">
                        Löschen
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button size="sm" variant="outline-secondary" @click="hide()">
                        Abbrechen
                    </b-button>
                </template>
            </b-modal>
            <b-modal id="loadingModal" no-close-on-backdrop @close="resetLoading" hide-footer>
                <b-spinner v-show="isLoading" variant="success" label="Spinning"></b-spinner>
                <br>
                <h3 :class="toggleClasses" v-if="loadingSuccess||loadingFailure">{{loadingText}}</h3>
                <br>
                <b-button class="confirm-button" variant="secondary" @click="resetLoading">Ok</b-button>
            </b-modal>
      </b-row>

  </div>
</template>

<script>
import data from '../../data/data.js'

export default {
    data : function(){
        return {
            standorte: {},
            options:[
                { value: '', text: 'Bitte wählen...' },
                { value: 'Krankmeldung', text: 'Krankmeldung' },
                { value: 'Fahrzeugschaden', text: 'Fahrzeugschaden' },
            ],
            selectedStandort: '',
            selectedName: '',
            selectedMail: '',
            selectedGrund: '',
            selectedId: null,
            isLoading: false,
            loadingSuccess: false,
            loadingFailure: false,
            loadingText: ''
        }
    },
    methods:{
        /**
         * Opens the modal to CHANGE an entry and executes a PUT request when saving
        */
        openModal: function(kontaktinfo, grundname, standort){
            this.selectedStandort = standort;
            this.selectedName = kontaktinfo.name;
            this.selectedMail = kontaktinfo.mail;
            this.selectedGrund = grundname;
            this.selectedId = kontaktinfo.id;
            this.$bvModal.show("modal-1");
        },

        /**
         * Removes old entry and add new from the server to get the new id (by splicing)
         * Just removes an entry, when it was deleted
         */
        removeEntry: function(load=null){
            var currentKontakt = this.standorte[this.selectedStandort][this.selectedGrund]
            for(var i =0; i < currentKontakt.length; i++){
                if(currentKontakt[i].id === this.selectedId){
                    load ? currentKontakt.splice(i, 1, load) : currentKontakt.splice(i, 1)
                }
            }
        },

        /**
         * Sets loading flags and confirmation text in the data depending on the success of the server request
         */
        loaded: function(successful, error=null){
            this.isLoading = false
            this.loadingSuccess = successful
            this.loadingFailure = !successful
            if(successful){
                this.loadingText = "Erfolgreich geladen und gespeichert!"
            }else{
                if(error){
                    this.loadingText = `Es hat einen Fehler gegeben: \n ${error}`
                }else{
                    this.loadingText = 'Es hat einen unbekannten Fehler gegeben.'
                }
            }
        },

        /**
         * Sets loading flags to loading status
         */
        loading: function(){
            this.isLoading = true
            this.loadingSuccess = false
            this.loadingFailure = false
        },

        /**
         * Resets all loading variables to have a fresh modal after repeatedly open/close a modal 
         */
        resetLoading: function(){
            this.isLoading = false
            this.loadingSuccess = false
            this.loadingFailure = false
            this.loadingText = ''
            this.$bvModal.hide('loadingModal')
        },

        /**
         * Executes a POST request, if a new entry is made or a PUT request, when an entry is changed by detecting, if an
         * Id is currently set
         * Executed by pressing 'Speichern' in the Modal
         */
        modalSave: function(){
            this.loading()

            this.$bvModal.show('loadingModal')

            const newContact = {
                'name': this.selectedName,
                'mail': this.selectedMail,
                'standort': this.selectedStandort,
                'grund': this.selectedGrund
            }
            if(this.selectedId){
                /**
                 * When Id is present, an update is executed
                 */
                data.putOnServer(`kontakt/${this.selectedId}`, newContact)
                    .then((res)=>{
                        this.removeEntry({
                            name: res.data.name,
                            mail: res.data.mail,
                            id: res.data._id
                        })
                        this.loaded(true)
                    })
                    .catch((err)=>{
                        this.loaded(false, err)
                    })
            }else{
                /**
                 * When NO Id is present, a new entry is posted to the server
                 */
                data.postOnServer('kontakt', newContact)
                .then((res)=>{
                    /**
                     * Push response in data object to save the ID created on the server
                     */
                    this.standorte[this.selectedStandort][this.selectedGrund].push({
                        name: res.data.name,
                        mail: res.data.mail,
                        id: res.data._id
                    })
                    this.loaded(true)
                })
                .catch((err)=>{
                    this.loaded(false, err)
                })
            }
        },

        /**
         * Deletes the selected entry and executes a DELETE request 
         */
        modalDelete: function(){
            this.loading()
            this.$bvModal.show('loadingModal')

            if(this.selectedId){
                data.deleteOnServer(`kontakt/${this.selectedId}`)
                    .then(()=>{
                        this.removeEntry()
                        this.loaded(true)
                    })
                    .catch((err)=>{
                        this.loaded(false, err)
                    })
            }
        },

        /**
         * Opens the modal to CREATE an entry and executes a POST request when saving
         */
        modalEmpty: function(standort, grundname){
            this.selectedStandort = standort;
            this.selectedName = "";
            this.selectedMail = "";
            this.selectedGrund = grundname;
            this.selectedId = null;
            this.$bvModal.show("modal-1");
        }
    },

    computed:{
        /**
         * Toggles classes of text depending on a successful request to the server
         * The text is green when successful, red otherwise
         */
        toggleClasses(){
            if(this.loadingSuccess){
                return "success-text success"
            }

            if(this.loadingFailure){
                return "success-text failure"
            }

            return "success-text"
        }
    },

    /**
     * Retrives all standorte, mailgrunds and kontakts from the server and sorts them into the standort property
     */
    async beforeMount(){
        /**
         * Get all standorte
         */
        await data.getAllFromServer('standort')
            .then((res)=>{
                for(const standort of res.data){
                    this.$set(this.standorte, standort.name, {})
                }
            })

        /**
         * Get all mailgrunds
         */
        await data.getAllFromServer('mailgrund')
            .then((res)=>{
                for(const mailgrund of res.data){
                    for(const value of Object.values(this.standorte)){
                        this.$set(value, mailgrund.name, [])
                    }
                }                
            })

        /**
         * Get all kontakts ands sorts them into the correct rows
         */
        await data.getAllFromServer('kontakt')
            .then((res)=>{
                for(const kontakt of res.data){
                    const kontaktArr = this.standorte[kontakt.standort.name][kontakt.grund.name]
                    this.$set(kontaktArr, kontaktArr.length, {
                        name: kontakt.name,
                        mail: kontakt.mail,
                        id: kontakt._id
                    })
                }
            })
    },

}
</script>

<style scoped>
.standortheading{
    text-align: left;
    padding: 15px 0 15px 0;
    color: green;
}

.success-text{
    text-align: center;
}

.success{
    color: green;
}

.failure{
    color: red;
}

.confirm-button{
    float: right;
}

.grundheading{
    text-align: left;
}

.standortarea{
    padding: 15px;
    border: 1px solid rgb(47, 47, 47);
    border-radius: 5px;
    margin-bottom: 5px;
}

.option-table:hover{
    color: whitesmoke;
    cursor: pointer;
    
}

.entry{
    margin: 5px 0 5px 0;
    font-size: 1.2em;
}
.first-entry{
    text-align: right;
}
.sec-entry{
    text-align: left;
}
</style>