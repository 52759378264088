import Vue from 'vue'
import App from './App.vue'
// Components
import MasterForm from './components/forms/MasterForm.vue'
import MailStart from './components/start-sites/mail-start.vue'
import HomeSite from './components/start-sites/HomeSite.vue'
import FahrtStart from './components/start-sites/fahrtenrechner-start.vue'
import SettingStart from './components/start-sites/einstellungen-start.vue'
import Fernfahrtenrechner from './components/forms/fahrtenrechner-form.vue'
import Tarife from './components/tables/tarife-table.vue'
import KontaktEinstellungen from './components/settings/kontakt-einstellungen.vue'
import MeldungEinstellungen from './components/settings/meldung-einstellungen.vue'
import StandortEinstellungen from './components/settings/standort-einstellungen.vue'
import TarifEinstellungen from './components/settings/tarif-einstellungen.vue'
import FahrzeugEinstellungen from './components/settings/fahrzeug-einstellungen.vue'
import BetreffEinstellungen from './components/settings/betreff-einstellungen.vue'
import Error404 from './components/errorpages/Error404.vue'
import Error401 from './components/errorpages/Error401.vue'

//login
import Login from './components/start-sites/login.vue'

//Bootstrap, router, vuex
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Make Router available
Vue.use(VueRouter)
// Use Vuex
Vue.use(Vuex)

Vue.config.productionTip = false

const data = require("./data/data")

const store = new Vuex.Store({
    state: {
        isLoggedIn: false,
        role: '',
        currentUser: ''
    },
    mutations: {
        setLoggedInStatus(state, newStatus){
            state.isLoggedIn = newStatus
        },
        setRole(state, newRole){
            state.role = newRole
        },
        setUsername(state, newUsername){
            state.currentUser = newUsername
        },
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        getRole: (state) => state.role,
        getUsername: state => state.currentUser
    }
})

const routes = [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {
            requiresAuth: false,
            redirectIfAuth: true
        }
    },
    {
        path: '/einstellungen/nachrichten',
        component: MeldungEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    {
        path: '/einstellungen/fahrzeuge',
        component: FahrzeugEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    {
        path: '/einstellungen/tarife',
        component: TarifEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    {
        path: '/einstellungen/standorte',
        component: StandortEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    {
        path: '/einstellungen/betreffe',
        component: BetreffEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    { 
        path: '/einstellungen/kontakte',
        component: KontaktEinstellungen,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    { 
        path: '/mailservice',
        component: MailStart,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/rechnerservice', 
        component: FahrtStart,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/rechnerservice/fernfahrtenrechner', 
        component: Fernfahrtenrechner,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/rechnerservice/tarife', 
        component: Tarife,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/einstellungen', 
        component: SettingStart,
        meta: {
            requiresAuth: true,
            requiresAdmin: true,
        }
    },
    { 
        path: '/mailservice/:id', 
        component: MasterForm,
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/', 
        component: HomeSite,
        name: "Home",
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    { 
        path: '/unauthorized', 
        component: Error401,
        name: "Error401",
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
        }
    },
    { 
        path: '*', 
        component: Error404,
        name: "Error404",
        meta: {
            requiresAuth: false,
            requiresAdmin: false,
        }
    },
]

var router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',
})

router.beforeEach( async (to, from, next) => {
    // this route requires auth, check if logged in
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // test if already logged in and set login status accordingly
        await data.testAuth()
            .then((res)=>{
                store.commit("setLoggedInStatus", true)
                store.commit("setRole", res.data.role)
                store.commit("setUsername", res.data.username)
            })
            .catch(()=>{
                store.commit("setLoggedInStatus", false)
                store.commit("setRole", '')
                store.commit("setUsername", '')
            })

        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next({ name: 'Login' })
        } else {
            if(to.matched.some(record => record.meta.requiresAdmin)){
                if(store.getters.getRole === 'admin' || store.getters.getRole === 'sysadmin'){
                    next() // go to wherever I'm going
                }else{
                    next({ name: 'Error401' })
                }
            }else{
                next() // go to wherever I'm going
            }
            
        }
    } else {
      next() // does not require auth, make sure to always call next()!
    }
  })

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
