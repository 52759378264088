<template>
<div>
  <h1>Tarife</h1>
  <b-table striped hover :items="cleanTarife"></b-table>
</div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
        return {
            'cleanTarife': [],
        }
    },
    mounted() {
        data.getAllFromServer('tarif')
            .then((res)=>{
                for(const tarif of res.data){
                    var newTarif = {
                        "Tarif": tarif.tarif,
                        "€/KM (innerhalb)": tarif.in_km_preis+"€",
                        "Pauschale (innerhalb)": tarif.in_pa_preis+"€",
                        "Freikilometer (innerhalb)": tarif.in_km_frei+" Km",
                        "€/KM (außerhalb)": tarif.out_km_preis+"€",
                        "Pauschale (außerhalb)": tarif.out_pa_preis+"€",
                        "Freikilometer (außerhalb)": tarif.out_km_frei+" Km",
                    }
                    this.cleanTarife.push(newTarif)

                }
            })
    }
}
</script>

<style>

</style>