<template>
    <div>
        <h1>Tarif Einstellungen</h1>
        <b-row>
            <b-col><h5><u>Name</u></h5></b-col>
            <b-col><h5><u>KM Preis innerhalb</u></h5></b-col>
            <b-col><h5><u>Pauschale innerhalb</u></h5></b-col>
            <b-col><h5><u>Freikm innerhalb</u></h5></b-col>
            <b-col><h5><u>KM Preis außerhalb</u></h5></b-col>
            <b-col><h5><u>Pauschale außerhalb</u></h5></b-col>
            <b-col><h5><u>Freikm außerhalb</u></h5></b-col>
        </b-row>
        <b-row class="tarif-table" @click="openModal(tarif, false)" v-for="tarif of tarife" :key="tarif.tarif">
            <b-col class="tarif-entry">{{tarif.tarif}}</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.in_km_preis).toFixed(2)}}€</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.in_pa_preis).toFixed(2)}}€</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.in_km_frei).toFixed(2)}}km</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.out_km_preis).toFixed(2)}}€</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.out_pa_preis).toFixed(2)}}€</b-col>
            <b-col class="tarif-entry">{{parseFloat(tarif.out_km_frei).toFixed(2)}}km</b-col>
        </b-row>
        <b-row>
            <b-col class="new-col" cols="2"><b-button class="w-100" variant="outline-success" @click="openModal({}, true)">Neu erstellen</b-button></b-col>
        </b-row>
        <b-modal id="tarifModal" :busy="true" no-close-on-backdrop @ok="sendTarif" @cancel="deleteTarif" :title="selectedTarif.tarif ? selectedTarif.tarif:'Neuer Tarif'">
                <span>
                    <label for="tarif-name">Tarifbezeichnung</label>
                    <b-form-input id="tarif-name" v-model="selectedTarif.tarif" placeholder="Name"></b-form-input>
                    <label for="tarif-km-in">Kilometerpreis innerhalb</label>
                    <b-form-input 
                    id="tarif-km-in"  
                    v-model="selectedTarif.in_km_preis" 
                    placeholder="0.00€"
                    lazy-formatter
                    :formatter="formatter"
                    ></b-form-input>
                    <label for="tarif-pa-in">Pauschale innerhalb</label>
                    <b-form-input 
                    id="tarif-pa-in" 
                    v-model="selectedTarif.in_pa_preis"  
                    placeholder="0.00€"
                    :formatter="formatter"
                    lazy-formatter
                    ></b-form-input>
                    <label for="tarif-km-infrei">Freikm innerhalb</label>
                    <b-form-input 
                    id="tarif-km-infrei" 
                    v-model="selectedTarif.in_km_frei"  
                    placeholder="0km"
                    :formatter="formatter"
                    lazy-formatter
                    ></b-form-input>
                    <label for="tarif-km-out">Kilometerpreis außerhalb</label>
                    <b-form-input 
                    id="tarif-km-out" 
                    v-model="selectedTarif.out_km_preis"  
                    placeholder="0.00€"
                    :formatter="formatter"
                    lazy-formatter
                    ></b-form-input>
                    <label for="tarif-km-in">Pauschale außerhalb</label>
                    <b-form-input 
                    id="tarif-pa-out" 
                    v-model="selectedTarif.out_pa_preis"  
                    placeholder="0.00€"
                    :formatter="formatter"
                    lazy-formatter
                    ></b-form-input>
                    <label for="tarif-km-outfrei">Freikm außerhalb</label>
                    <b-form-input 
                    id="tarif-km-outfrei" 
                    v-model="selectedTarif.out_km_frei"  
                    placeholder="0km"
                    :formatter="formatter"
                    lazy-formatter
                    ></b-form-input>
                </span>
                <br>
                <div>
                    <b-form-checkbox v-if="!newTarif" v-model="deleteOption">
                        Ich möchte diesen Tarif löschen
                    </b-form-checkbox>
                </div>
                <template #modal-footer="{ ok, cancel, hide }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" variant="success" @click="ok()">
                        Speichern
                    </b-button>
                    <b-button size="sm" :disabled="!deleteOption" variant="danger" @click="cancel()">
                        Löschen
                    </b-button>
                    <!-- Button with custom close trigger value -->
                    <b-button size="sm" variant="outline-secondary" @click="hide()">
                        Abbrechen
                    </b-button>
                </template>
            </b-modal>
            <b-modal id="loadingModal" ok-only>
                <b-spinner v-if="isLoading" variant="success" label="Spinning"></b-spinner>
                <h3 v-else :class="loadingSuccess ? 'success-text' : 'failure-text'">{{loadingText}}</h3>
            </b-modal>
    </div>
</template>

<script>
import data from '../../data/data'
export default {
    data: function(){
        return {
            tarife: [],
            selectedTarif: {},
            newTarif: false,
            isLoading: false,
            loadingSuccess: false,
            loadingFailure: false,
            loadingText: '',
            deleteOption: false
        }
    },
    methods:{
        /**
         * Opens the modal
         * When an entry of the table is clicked, newTarif is false, when the "new" button is clicked newTarif is true
         * Determines if the following request is a put or a post request
         */
        openModal: function(tarif, newTarif){
            this.newTarif = newTarif
            this.selectedTarif = JSON.parse(JSON.stringify(tarif))
            this.$bvModal.show('tarifModal')
        },
        /**
         * Sends delete request to server to delete selectedTarif
         */
        deleteTarif: function(){
            this.loading()
            data.deleteOnServer(`tarif/${this.selectedTarif._id}`)
                .then((res)=>{
                    this.loaded(true)
                    // Find index of the selected tarif to exchange
                    for(var i=0; i<this.tarife.length; i++){
                        if(this.tarife[i].tarif === res.data.tarif){
                            this.tarife.splice(i, 1)
                        }
                    }
                })
                .catch((err)=>{
                    this.loaded(false, err)
                })
        },
        /**
         * Sends a post or put request to the server, depending on newTarif variable
         * Exchanges all possible ',' with '.' to prevent database errors
         */
        sendTarif: function(){
            this.loading()
            //If its a new Tarif, execute post request
            if(this.newTarif){
                data.postOnServer('tarif', this.selectedTarif)
                    .then((res)=>{
                        this.loaded(true)
                        this.tarife.push(res.data)
                    })
                    .catch((err)=>{
                        this.loaded(false, err)
                    })
            //Else put request
            }else{
                data.putOnServer(`tarif/${this.selectedTarif._id}`, this.selectedTarif)
                    .then((res)=>{
                        this.loaded(true)
                        // Find index of the selected tarif to exchange
                        for(var i=0; i<this.tarife.length; i++){
                            if(this.tarife[i].tarif === res.data.neu.tarif){
                                this.tarife.splice(i, 1, res.data.neu)
                            }
                        }
                    })
                    .catch((err)=>{
                        this.loaded(false, err)
                    })
            }
        },
        /**
         * Sets modal into loading state
        */
        loading: function(){
            this.isLoading = true
            this.loadingSuccess = false
            this.loadingFailure = false
            this.loadingText = ''
            this.$bvModal.show('loadingModal')
        },
        /**
         * Sets modal into a loaded state, depending on success ore failure of the request
         */
        loaded: function(successful, error){
            this.isLoading = false
            this.loadingSuccess = successful
            this.loadingFailure = !successful
            this.deleteOption = false
            if(successful){
                this.loadingText = "Erfolg!"
            }else{
                this.loadingText = `${error}`
            }
        },
        /**
         * Formats the value of the input field so no characters except numbers and dots (.) can be used
         */
        formatter: function(value){
            return parseFloat(
                String(value)
                .replace(',', '.')
                .replace(/[^0-9,.]+/g, "")
                ).toFixed(2)
        }
    },
    /**
     * Gets all Tarife from server before mount
     */
    beforeMount: function(){
        data.getAllFromServer('tarif')
            .then((res)=>{
                this.tarife = res.data
            })
            .catch((err)=>{
                console.log(err)
            })
    }
}
</script>

<style>
.tarif-table{
    font-size: 1.3em;
}

.tarif-table:hover{
    color: whitesmoke;
    cursor: pointer;
}

.new-col{
    padding: 5px 0 5px 0;
}

</style>