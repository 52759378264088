/**
 * File for request to the server
 */

const axios = require("axios")

/**
 * 
 * @param {*} endpoint endpoint to which the requests should go
 * @returns the entire url depending on dev or prod system
 */
function getURL(endpoint){
    return process.env.NODE_ENV === 'development' ? `http://localhost:3000/${endpoint}` : `https://meldesystem-gorris.de/${endpoint}`
}

/**
 *  
 * @returns Version of the app
 */
exports.getVersion = function(){
    return axios.get(getURL('version'))
}

/**
 *  Destroys session and logs user out
 * @returns Logout Promise
 */
exports.logout = function(){
    return axios.get(getURL('logout'))
}

/**
 * 
 * @param {*} endpoint endpoint to which the requests should go
 * @returns axios promise
 */
exports.getAllFromServer = function(endpoint){
    return axios.get(getURL(endpoint))
}

/**
 * 
 * @param {*} endpoint endpoint to which the requests should go
 * @param {*} load payload for the post request
 * @returns axios promise
 */
exports.postOnServer = function(endpoint, load){
    return axios.post(getURL(endpoint), load)
}

/**
 * 
 * @param {*} endpoint endpoint to which the requests should go
 * @param {*} load payload for the post request
 * @returns axios promise
 */
exports.putOnServer = function(endpoint, load){
    return axios.put(getURL(endpoint), load)
}

/**
 * 
 * @param {*} endpoint endpoint to which the requests should go
 * @returns axios promise
 */
exports.deleteOnServer = function(endpoint){
    return axios.delete(getURL(endpoint))
}

exports.sendMail = function(message){
    var endpoint = null;
    if(process.env.NODE_ENV === "development"){
        endpoint = "http://localhost:3000/sendmail"
    }else{
        endpoint = "https://meldesystem-gorris.de/sendmail" 
    }
    return axios.post(endpoint, message)
}

exports.login = function(data){
    var endpoint = null;
    if(process.env.NODE_ENV === "development"){
        endpoint = "http://localhost:3000/login"
    }else{
        endpoint = "https://meldesystem-gorris.de/login" 
    }
    return axios.post(endpoint, data)
}

exports.testAuth = function(){
    var endpoint = null;
    if(process.env.NODE_ENV === "development"){
        endpoint = "http://localhost:3000/checklogin"
    }else{
        endpoint = "https://meldesystem-gorris.de/checklogin" 
    }

    return axios.get(endpoint)
}