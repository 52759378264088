<template>
<div>
    <h1>Mail Service</h1>
        <b-row>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='ceo.svg'
                buttonText="Zum Formular"
                imgAlt="Geschäftsführer"
                title="Mail an die Geschäftsführung"
                text="Hier kannst du eine Nachricht an die Geschäftsführung senden."
                to="/mailservice/ceo-meldung"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='patient.svg'
                buttonText="Zum Formular"
                imgAlt="krank"
                title="Krankmeldung einreichen"
                text="Melde dich hier krank. Gute Besserung!"
                to="/mailservice/krankmeldung"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='fender-bender.svg'
                buttonText="Zum Formular"
                imgAlt="Fahrzeugschaden"
                title="Fahrzeugschaden melden"
                text="Erstatte eine Meldung über einen entdeckten Fahrzeugschaden."
                to="/mailservice/schadenmeldung"
                />
            </b-col>

            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='biohazard-sign.svg'
                buttonText="Zum Formular"
                imgAlt="Infektion/Desinfektion"
                title="Infektion/Desinfektion melden"
                text="Melde hier eine Infektion oder Desinfektion."
                to="/mailservice/infektion"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='package.svg'
                buttonText="Zum Formular"
                imgAlt="Medizinprodukte"
                title="Medizinprodukte melden"
                text="Melde hier Informationen über Medizinprodukte."
                to="/mailservice/medizinprodukte"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='dissatisfaction.svg'
                buttonText="Zum Formular"
                imgAlt="beschweren"
                title="Beschwerde einreichen"
                text="Beschwere dich hier bei einem Verantwortlichen."
                to="/mailservice/beschwerde"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='info.svg'
                buttonText="Zum Formular"
                imgAlt="Info"
                title="Infobox anfragen"
                text="Erhalte hier Informationen über alles Mögliche."
                to="/mailservice/infobox"
                />
            </b-col>
        </b-row>
    <br>
</div>
</template>

<script>
    import MeldeCards from "../cards/MeldeCards.vue"
export default {
    components: {
        'MeldeCards': MeldeCards
    }, 
}
</script>

<style>

</style>