<template>
  <div>
      <h1>Einstellungen</h1>
      <b-row>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='map.svg'
                buttonText="Ändern"
                imgAlt="Map"
                title="Standort Einstellungen"
                text="Hier kannst du Standorte hinzufügen, ändern oder löschen."
                to="/einstellungen/standorte"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='teacher.svg'
                buttonText="Ändern"
                imgAlt="kontakt"
                title="Kontakt Einstellungen"
                text="Hier kannst du Formularkontakte hinzufügen, ändern oder löschen."
                to="/einstellungen/kontakte"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='ambulance.svg'
                buttonText="Ändern"
                imgAlt="Ambulanz"
                title="Fahrzeug Einstellungen"
                text="Hier kannst du Fahrzeuge hinzufügen, ändern oder löschen."
                to="/einstellungen/fahrzeuge"
                />
            </b-col>

            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='accounting.svg'
                buttonText="Ändern"
                imgAlt="tarife"
                title="Tarif Einstellungen"
                text="Hier kannst du Tarife für den Fernfahrtenrechner hinzufügen, ändern oder löschen."
                to="/einstellungen/tarife"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='conversation.svg'
                buttonText="Zur Übersicht"
                imgAlt="nachrichten"
                title="Nachrichten Übersicht"
                text="Hier hast du eine Übersicht über alle versendeten Nachrichten über die Kontaktformulare."
                to="/einstellungen/nachrichten"
                />
            </b-col>
            <b-col cols="12" sm="6" md="4" lg="4" xl="3">
                <MeldeCards
                imgSource='email.svg'
                buttonText="Betreffzeilen"
                imgAlt="betreff"
                title="Mail-Betreffe ändern"
                text="Hier kannst du Betreffzeilen für die versendeten Emails festlegen und ändern."
                to="/einstellungen/betreffe"
                />
            </b-col>
        </b-row>
  </div>
</template>

<script>
    import MeldeCards from "../cards/MeldeCards.vue"
export default {
    components: {
        MeldeCards
    }, 
}
</script>

<style>

</style>