<template>
    <div>
        <h1>Gesendete Nachrichten</h1>
        <h2>Anzahl: {{sortedMeldungen.length}}</h2>
        <br>
        <b-row>
            <b-col cols="3" offset="1">
                <b-form-datepicker 
                v-model="dateFilter" 
                class="mb-2"
                placeholder="Datumsfilter"
                start-weekday="1"
                locale="de"
                ></b-form-datepicker>
            </b-col>
            <b-col cols="1">
                <b-button variant="danger" @click="() => dateFilter = ''">Reset</b-button>
            </b-col>
            <b-col cols="7" class="filler"></b-col>
        </b-row>
        <br>
        <b-row>
            <b-col cols="1" class="filler"></b-col>
            <b-col cols="2"><h4><u>Datum</u></h4></b-col>
            <b-col cols="2"><h4><u>Standort</u></h4></b-col>
            <b-col cols="2"><h4><u>Grund</u></h4></b-col>
            <b-col cols="2"><h4><u>Sender</u></h4></b-col>
            <b-col cols="2"><h4><u>Text</u></h4></b-col>
            <b-col cols="1" class="filler"></b-col>
        </b-row>
        <span class="meldung" @click="openModal(meldung)" v-for="meldung of sortedMeldungen" :key="generateKey(meldung)">
            <b-row>
                <b-col cols="1" class="filler"></b-col>
                <b-col cols="2">{{meldung.date}}</b-col>
                <b-col cols="2">{{meldung.standort}}</b-col>
                <b-col cols="2">{{meldung.grund}}</b-col>
                <b-col cols="2">{{meldung.name}}</b-col>
                <b-col cols="2">{{meldung.message.substring(0,15)+'...'}}</b-col>
                <b-col cols="1" class="filler"></b-col>
            </b-row>
        </span>
        <b-modal id="meldungModal" size="lg" ok-only>
            <b-row>
                <b-col cols="3"><u>Datum:</u></b-col>
                <b-col cols="3">{{selectedMeldung.date}}</b-col>
                <b-col cols="3"><u>Zeit:</u></b-col>
                <b-col cols="3">{{selectedMeldung.time}} Uhr</b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="3"><u>Standort:</u></b-col>
                <b-col cols="3">{{selectedMeldung.standort}}</b-col>
                <b-col cols="3"><u>Grund:</u></b-col>
                <b-col cols="3">{{selectedMeldung.grund}}</b-col>
            </b-row>
            <br>
            <b-row>
                <b-col cols="12"><u>Name:</u></b-col>
                <b-col cols="12">{{selectedMeldung.name}}</b-col>    
            </b-row>
            <b-row v-if="selectedMeldung.reported">
                <b-col cols="12"><u>Um wen ging es?:</u></b-col>
                <b-col cols="12">{{selectedMeldung.reported}}</b-col>    
            </b-row>
            <br>
            <b-row>
                <b-col v-if="selectedMeldung.phone" cols="12"><u>Fon:</u></b-col>
                <b-col v-if="selectedMeldung.phone" cols="12">{{selectedMeldung.phone}}</b-col>
            </b-row>
            <br v-if="selectedMeldung.phone">
            <b-row>
                <b-col v-if="selectedMeldung.fahrzeug" cols="3"><u>Fahrzeug:</u></b-col>
                <b-col v-if="selectedMeldung.fahrzeug" cols="3">{{selectedMeldung.fahrzeug}}</b-col>
            </b-row>
            <br v-if="selectedMeldung.fahrzeug">
            <b-row>
                <b-col v-if="selectedMeldung.sickStartDate" cols="12"><u>Erster Krankheitstag:</u></b-col>
                <b-col v-if="selectedMeldung.sickStartDate" cols="12">{{selectedMeldung.sickStartDate}}</b-col>
                <b-col v-if="selectedMeldung.sickEndDate" cols="12"><u>Letzter Krankheitstag:</u></b-col>
                <b-col v-if="selectedMeldung.sickEndDate" cols="12">{{selectedMeldung.sickEndDate}}</b-col>
            </b-row>
            <br v-if="selectedMeldung.sickEndDate||selectedMeldung.sickStartDate">
            <b-row>
                <b-col cols="12"><u>Zugestellt an:</u></b-col>
                <b-col v-for="recipient of selectedMeldung.successfullRecipients" :key="recipient" cols="12">{{recipient}}</b-col>  
            </b-row>
            <br>
            <b-row>
                <b-col cols="12"><u>Fehler bei Zustellung:</u></b-col>
                <b-col v-for="recipient of selectedMeldung.flawedRecipients" :key="recipient" cols="12">{{recipient}}</b-col>
            </b-row>
            <br>
            <p>{{selectedMeldung.message}}</p>
        </b-modal>
    </div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
        return {
            meldungen: [],
            errResponse: '',
            selectedMeldung: {},
            dateFilter: ''
        }
    },
    /**
     * Shows modal with selected meldung-data
     */
    methods:{
        /**
         * Defines selectedMeldung and shows it in the modal
         */
        openModal: function(meldung) {
            this.selectedMeldung = meldung
            this.$bvModal.show("meldungModal")
        },
        /**
         * Generates unique key
         */
        generateKey: function(meldung){
            return `${meldung.date}-${meldung.time}-${meldung.standort}-${meldung.grund}`
        }
    },
    computed:{
        /**
         * Sorts all meldungen and displays newer ones first
         */
        sortedMeldungen(){
            return this.meldungen.slice()
            .filter((meldung)=>{
                if(this.dateFilter){
                    const formattedFilterArray = this.dateFilter.split('-')
                    return meldung.date === `${formattedFilterArray[2]}.${formattedFilterArray[1]}.${formattedFilterArray[0]}`
                }else{
                    return true
                }
            })
            .sort((first, second)=>{
                //Extract year, month day
                var firstYear = parseInt(first.date.split('.')[2], 10)
                var firstMonth = parseInt(first.date.split('.')[1], 10)
                var firstDay = parseInt(first.date.split('.')[0], 10)
                var secYear = parseInt(second.date.split('.')[2], 10)
                var secMonth = parseInt(second.date.split('.')[1], 10)
                var secDay = parseInt(second.date.split('.')[0], 10)

                //First sort by year, then by month, last by day
                if(secYear-firstYear !== 0) return secYear-firstYear
                if(secMonth-firstMonth !==0) return secMonth-firstMonth
                if(secDay-firstDay !==0) return secDay-firstDay

                //When dates are equal, sort by time
                //Extract minutes and hours
                var firstHour = parseInt(first.time.split(':')[0], 10)
                var firstMinute =  parseInt(first.time.split(':')[1], 10)
                var secHour =  parseInt(second.time.split(':')[0], 10)
                var secMinute =  parseInt(second.time.split(':')[1], 10)
                
                //Return minute difference as last check when hours are equal
                return secHour-firstHour === 0 ? secMinute-firstMinute : secHour-firstHour

            })
        }
    },
    /**
     * Executes befor mounting and GETs all meldungen from server
     */
    async beforeMount(){
        await data.getAllFromServer('meldung')
            .then((res)=>{
                this.meldungen = res.data
            })
            .catch((err)=>{
                this.errResponse = 'Hier ist was schief gelaufen, bitte neu laden!'
                console.log(err)
            })
    }
}
</script>

<style scoped>
.meldung{
    font-size: 1.2em;
}
.meldung:hover{
    color: whitesmoke;
    cursor: pointer;
}
</style>