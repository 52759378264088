<template>
  <div class="card-border">
    <b-card
        :title="title"
        :img-src="require('./../../assets/'+imgSource)"
        :img-alt="imgAlt"
        img-top
        style="width: 100%; height: 100%; min-height: 350px;"
        class="mb-2"
        img-height="128"
        img-width="128"
        :to="to"
    >
        <b-card-text>
            {{text}}
        </b-card-text>
        <b-button variant="primary" @click="$router.push(to)">{{buttonText}}</b-button>    
    </b-card>
  </div>
</template>

<script>
export default {
    props: {
        title: String,
        imgSource: String,
        imgAlt: String,
        text: String,
        buttonText: String,
        to: String
    },
}
</script>

<style>

.card-img-top{
    padding-top: 10px;
}

.card-border{
    margin: 5px;
}
</style>