<template>
  <div>
    <b-modal v-model="modalShow" no-close-on-backdrop ok-only>
        <div class="d-flex justify-content-center mb-3">
            <b-spinner v-if="isLoading" variant="success" label="Spinning"></b-spinner>
            <h3 :class="toggleClasses" v-if="!isLoading" v-html="loadingText"></h3><br>
        </div> 
        <div class="recepients-success" v-if="!isLoading&&recepientsSuccess.length>0">Email gesendet an:</div>     
        <div class="recepients-success" v-for="recipient of recepientsSuccess" :key="recipient">
            {{recipient}}<br>
        </div>
        <br>
        <div class="recepients-failure" v-if="!isLoading&&recepientsFailure.length>0">Email konnte nicht gesendet werden an:</div>
        <div class="recepients-failure" v-for="recipient of recepientsFailure" :key="recipient">
            {{recipient}}<br>
        </div>
        <div class="recepients-failure" v-if="loadingFailure">{{errorText}}</div>
    </b-modal>
  </div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
        return {
            modalShow: false,
            isLoading: false,
            loadingSuccess: false,
            loadingFailure: false,
            loadingText: '',
            errorText: '',
            recepientsSuccess: [],
            recepientsFailure: []
        }
    },
    methods:{
        /**
         * Sets modal into loading state
        */
        loading: function(){
            this.modalShow = true
            this.isLoading = true
            this.loadingSuccess = false
            this.loadingFailure = false
            this.loadingText = ''
            this.errorText = ''
        },

        /**
         * Sets modal into a loaded state, depending on success ore failure of the request
         */
        loaded: function(successful, error){
            this.isLoading = false
            this.loadingSuccess = successful
            this.loadingFailure = !successful
            if(successful){
                this.loadingText = "Erfolg!"
                if(this.textFields.subject === 'Krankmeldung') this.loadingText += '<br> Bitte den Mitarbeiter in allen Systemen als krank vermerken.'
            }else{
                this.loadingText = `Fehler!`
                this.errorText = `${error}`
            }
        },

        /**
         * Executes a POST request to the server to send the mail with all corresponding data
         * Saves the response to show the sender, which recepients were contacted and which couldn't
         */
        submit: function(){
            this.loading()

            data.sendMail(this.textFields)
                    .then((res)=>{
                            this.recepientsSuccess = res.data.recepientsSuccess
                            this.recepientsFailure = res.data.recepientsFailure
                            this.loaded(true)
                            this.$emit('submitSuccess') //clear fields on listeners
                    })
                    .catch((err)=>{
                        this.loaded(false, `${err.response.status}: ${err.response.data.message}`)
                        this.$emit('submitFailure')
                    })
        }
    },
    computed:{
        /**
         * Depending on a successful delivery of data, the text corresponds to a different class
         */
        toggleClasses(){
            if(this.loadingSuccess){
                return "recepients-success" 
            }else{
                return "recepients-failure"
            }    
        },
    },
    props: {
        textFields: Object,
    }
}
</script>

<style scoped>

.recepients-success{
    color: green;
}

.recepients-failure{
    color: red;
}

</style>