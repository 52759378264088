<template>
    <div>
        <h1>401 Nicht autorisiert...</h1>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>