<template>
<div>
    <h1>Rechner Service</h1>
        <b-row>
            <b-col cols="10" sm="10" md="10" lg="4" offset-lg="2">
                <MeldeCards
                imgSource='calculator.svg'
                buttonText="Zum Rechner"
                imgAlt="Rechner"
                title="Fahrtenrechner"
                text="Hier kannst du die Preise für Deine Fahrten errechnen."
                eventParam="fahrtenrechner"
                eventName="changeContent"
                @changeContent="$emit('changeContent', $event)"
                to="rechnerservice/fernfahrtenrechner"
                />
            </b-col>
            <b-col cols="10" sm="10" md="10" lg="4">
                <MeldeCards
                imgSource='spreadsheet.svg'
                buttonText="Übersicht öffnen"
                imgAlt="Tarifübersicht"
                title="Tarife einsehen"
                text="Übersicht über alle Tarife auf einen Blick."
                eventName='changeContent'
                eventParam="tarife"
                @changeContent="$emit('changeContent', $event)"
                to="rechnerservice/tarife"
                />
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
</div>
</template>

<script>
    import MeldeCards from "../cards/MeldeCards.vue"
export default {
    components: {
        MeldeCards
    },   

}
</script>

<style>

</style>