<template>
  <div>
      <h1>Willkommen im Meldesystem</h1>
      <br>
      <small>Version: {{version}}</small>
  </div>
</template>

<script>
import data from '../../data/data'
export default {
    data: function(){
        return {
            version: ''
        }
    },
    beforeMount(){
        data.getVersion()
            .then(res=>{
                this.version = res.data.version
            })
    }
}
</script>

<style scoped>
    .startimg{
        max-height: 400px;
        max-width: 300px;
    }
</style>