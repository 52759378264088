<template>
    <div>
        <h1>Betreff für Emails</h1>
        <br>
        <p class="notice">
            Willkommen zum neuen Feature! <br>
            Jede Betreffzeile ist einem Email-Formular zugeordnet.
            Klicke auf den blauen Button über dem Betreff für das jeweilige Formular, um ein Datenfeld einzusetzen, oder hover über den Button, um den Text zu sehen, der dann eingesetzt wird. <br>
            Du hast auf alle Datenfelder zugriff, die auch in dem Formular ausgefüllt werden! 
            Die Datenfelder werden dann auf dem Server mit dem jeweils im Formular eingetragenen Wert ausgetauscht.<br>
            <strong>Beispiel:</strong> $$fahrzeug$$ wird im Betreff zu 344599 (Fahrzeugnummer), falls der Absender diese Fahrzeugnummer ausgewählt hat. <br> <br>
            <strong>Hinweis:</strong> Es dürfen <strong>keine $$</strong> (2 Dollarzeichen hintereinander) mehr im Betreff verwendet werden. Ein einzelnes $ Zeichen ist aber in Ordnung.
        </p>
        <b-row v-for="grund of mailgrunds" :key="grund">
            <b-col cols="12"> <h2>{{grund}}</h2> </b-col>
            <span class="datawrapper">
                <span class="dataelem" v-for="(data, index) in datafields[grund]" :key="index">
                    <b-button 
                    @click="addData(grund, datafield, index)"
                    v-b-tooltip.hover :title="'$$'+data[datafield]+'$$'"
                    variant="primary" 
                    size="sm" 
                    v-for="datafield of Object.keys(data)" :key="datafield"
                    >{{datafield}}</b-button>
                </span>
            </span>
            
            <b-form-input 
            size="lg" 
            placeholder="Betreff"
            v-model="betreff[grund].content"
            class="betreff"
            />
            <br>
        </b-row>
        <b-button @click="saveAll" size="lg" variant="success">Alle speichern</b-button>
        <br>
        <div :class="success? 'success-text': 'failure-text'">{{sendText}}</div>
    </div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data(){
        return {
            mailgrunds: [],
            betreff:{},
            datafields:{},
            sendText: '',
            success: true
        }
    },
    methods:{
        addData: function(grund, key, index){
            this.betreff[grund].content += ` $$${this.datafields[grund][index][key]}$$`
        },
        saveAll: async function(){
            for(const [key, value] of Object.entries(this.betreff)){
                await data.putOnServer(`betreff/${value._id}`, value)
                    .then(res => {
                        this.betreff[key] = res.data
                        this.sendText = 'Erfolgreich gespeichert'
                    })
                    .catch(err=>{
                        console.log(err)
                        this.sendText = 'Fehler aufgetreten!'
                    })
            }

            setTimeout(()=>{
                this.sendText = ''
            }, 5000)
        }
    },
    async beforeMount(){
        /**
         * Get all mailgrunds
         */
        await data.getAllFromServer('mailgrund')
            .then((res)=>{
                for(const mailgrund of res.data){
                    this.mailgrunds.push(mailgrund.name)
                    this.$set(this.datafields, mailgrund.name, [])
                    switch (mailgrund.name) {
                        case 'Krankmeldung':
                            this.$set(this.datafields, mailgrund.name, [
                                {'Standort':'standort'}, 
                                {'Sender':'senderName'}, 
                                {'Um wen gehts?': 'reported'}, 
                                {'Beginndatum':'sickStartDate'}, 
                                {'Enddatum':'sickEndDate'}
                                ])
                            break;
            
                        case 'Mail an Geschäftsführung':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'}, {'Telefon':'telNumber'}, {'Geschäftsführer':'ceo'}])
                            break;
            
                        case 'Fahrzeugschaden':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'}, {'Um wen gehts?': 'reported'}, {'Fahrzeug':'fahrzeug'}])
                            break;
            
                        case 'Infektion/Desinfektion':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'}, {'Fahrzeug':'fahrzeug'}])
                            break;
            
                        case 'Beschwerde':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'}, {'Telefon':'telNumber'}])
                            break;
            
                        case 'Infobox':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'},])
                            break;

                        case 'Medizinprodukte':
                            this.$set(this.datafields, mailgrund.name, [{'Standort':'standort'}, {'Sender':'senderName'}, {'Um wen gehts?': 'reported'}, {'Fahrzeug':'fahrzeug'}])
                            break;
            
                        default:
                            break;
                    }
                }
            })
        data.getAllFromServer('betreff')
            .then(async res =>{
                if(res.data.length === 0){
                    for(const mailgrund of this.mailgrunds){
                        await data.postOnServer('betreff', {grund: mailgrund, content: ''})
                            .then(res=>{
                                this.betreff[mailgrund] = res.data
                            })
                    }
                }else{
                    for(const betreff of res.data){
                        this.$set(this.betreff, betreff.grund.name, betreff)
                    }
                }
            })
            .catch(err=>{
                console.log(err)
            })
    },
}
</script>

<style scoped>

.betreff{
    margin: 0 50px;
}

.datawrapper{
    margin: 0 50px;
}

.dataelem{
    margin: 10px 10px;
}

.success-text{
    color: green;
}

.failure-text{
    color: red;
}

.notice{
    font-size: 20px;
}

</style>