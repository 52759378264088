<template>
    <div>
        <h1>Standorte</h1>
        <br>
        <b-row>
            <b-col cols="12" sm="6" md="6" lg="3" xl="3" v-for="standort of standorte" :key="standort.name">
                <b-card bg-variant="light" border-variant="secondary" :header="standort.name" class="text-center standort-card">
                    <b-row>
                        <b-col cols="6">
                            <b-button variant="outline-warning" @click="showModalChange(standort)">Ändern</b-button>
                        </b-col>
                        <b-col cols="6">
                            <b-button variant="outline-danger" @click="showModalDelete(standort)">Löschen</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                <b-card bg-variant="light" border-variant="success" header="Neuer Standort" class="text-center standort-card">
                    <b-row>
                        <b-col cols="12">
                            <b-button variant="outline-success" @click="showModalCreate">Erstellen</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="putModal" no-close-on-backdrop @ok="putStandort" :title="selectedStandort.name">
            <label for="standort-name">Name</label>
            <b-form-input id="standort-name" v-model="newName"></b-form-input>
        </b-modal>
        <b-modal id="createModal" @ok="createStandort" title="Neuer Standort">
            <label for="standort-name2">Name</label>
            <b-form-input id="standort-name2" v-model="newName"></b-form-input>
        </b-modal>
        <b-modal id="loadingModal" ok-only>
            <b-spinner v-if="isLoading" variant="success" label="Spinning"></b-spinner>
            <h3 v-else :class="loadingSuccess ? 'success-text' : 'failure-text'">{{loadingText}}</h3>
        </b-modal>
        <b-modal id="deleteModal" @ok="deleteStandort" :title="selectedStandort.name+' löschen'">
            <h3>Sicher, dass du <b>{{selectedStandort.name}} löschen</b> möchtest?</h3>
        </b-modal>
    </div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
        return {
            standorte: [],
            selectedStandort: {},
            newName: '',
            isLoading: false,
            loadingSuccess: false,
            loadingFailure: false,
            loadingText: ''
        }
    },
    methods:{
        /**
         * Shows modal to change standort information
         */
        showModalChange: function(standort) {
            //Open modal to change the name
            this.selectedStandort = standort
            this.newName = standort.name
            this.$bvModal.show('putModal')
        },
        /**
         * Shows modal to create new standort
         */
        showModalCreate: function(){
            this.$bvModal.show('createModal')
        },
        /**
         * Shows modal to confirm deletion
         */
        showModalDelete: function(standort){
            this.selectedStandort = standort
            this.$bvModal.show('deleteModal')
        },
        /**
         * Create request to server to create new standort
         */
        createStandort: function(){
            this.loading()
            data.postOnServer('standort', {name: this.newName})
                .then((res)=>{
                    this.loaded(true)
                    this.standorte.push(res.data)
                })
                .catch((err)=>{
                    this.loaded(false, err)
                })
            this.newName = ''
        },
        /**
         * Delete request to the server to delete the selected standort
         */
        deleteStandort: function(){
            this.loading()
            data.deleteOnServer(`standort/${this.selectedStandort.name}`)
                .then(()=>{
                    this.loaded(true)
                    // Find index of the selected standort to delete and splice it
                    const index = this.standorte.indexOf(this.selectedStandort)
                    if(index > -1){
                        this.standorte.splice(index, 1)
                        console.log("Gefunden!")
                    }else{
                        console.log("Nicht gefunden...")
                    }
                })
        },
        /**
         * Put request to server to change standort name
         */
        putStandort: function() {
            this.loading()
            data.putOnServer(`standort/${this.selectedStandort.name}`, {name: this.newName})
                .then(()=>{
                    this.loaded(true)
                    this.selectedStandort.name = this.newName
                    this.newName = ''
                })
                .catch((err)=>{
                    this.loaded(false, err)
                    this.newName = ''
                })
        },
        /**
         * Sets modal into loading state
        */
        loading: function(){
            this.isLoading = true
            this.loadingSuccess = false
            this.loadingFailure = false
            this.loadingText = ''
            this.$bvModal.show('loadingModal')
        },
        /**
         * Sets modal into a loaded state, depending on success ore failure of the request
         */
        loaded: function(successful, error){
            this.isLoading = false
            this.loadingSuccess = successful
            this.loadingFailure = !successful
            if(successful){
                this.loadingText = "Erfolg!"
            }else{
                this.loadingText = `${error}`
            }
        },
    },
    /**
     * Load all standorte before mount
     */
    beforeMount(){

        data.getAllFromServer('standort')
            .then((res)=>{
                this.standorte = res.data
            })
            .catch((err)=>{
                console.log(err)
            })
    }
}
</script>

<style scoped>
    .standort-card{
        margin-bottom: 10px;
        max-width: 20rem;
    }

    .success-text{
        color: green;
    }

    .failure-text{
        color: red;
    }
</style>