<template>
<div>
    <h1>Fahrtenrechner</h1>
    <b-form>
        <!-- Tarif -->
        <b-form-row class="justify-content-center">
            <b-col cols="6">
                <b-form-group
                    id="input-group-standort"
                >
                    <b-form-select v-model="form.selectedTarif" :options="form.tarifliste"></b-form-select>
                </b-form-group>
            </b-col>
        </b-form-row>
        <!-- Streckendaten -->
        <span v-if="!(form.selectedTarif == 'null')">
        <h2 class="text-left">Streckendaten</h2>
        <b-form-row align-v="center">
            <b-col cols="3"></b-col>
            <b-col cols="6">
                <b-form-group
                    label-cols="7"
                    content-cols="5"
                    label="Standort -> EO (km)"
                    label-align="right"
                    label-for="input-SO-EO"
                >
                    <b-form-input
                        id="input-SE-EO"
                        v-model="form.soeo"
                        type="number"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3"></b-col>
        </b-form-row>
        <b-form-row>
            <b-col cols="3"></b-col>
            <b-col cols="6">
                <b-form-group
                    label-cols="7"
                    content-cols="5"
                    label="EO -> ZO (km)"
                    label-align="right"
                    label-for="input-EO-ZO"
                >
                    <b-form-input
                        id="input-EO-ZO"
                        v-model="form.eozo"
                        type="number"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3"></b-col>
        </b-form-row>
        <b-form-row>
            <b-col cols="3"></b-col>
            <b-col cols="6">
                <b-form-group
                    label-cols="7"
                    content-cols="5"
                    label="ZO -> Standort (km)"
                    label-align="right"
                    label-for="input-ZO-SO"
                >
                    <b-form-input
                        id="input-ZO-SO"
                        v-model="form.zoso"
                        type="number"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="3"></b-col>
        </b-form-row>

    <b-form-row>
        <b-col cols="6">

        <!-- ####################### Innerorts ####################### -->

        <h2 class="text-left">Innerorts</h2>
        <b-form-row><!-- Kilometer -->
            <b-col>
                <b-input-group prepend="Kilometer" append="Km" style="padding: 10px;">
                    <b-form-input disabled
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKilometerAllInner"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Freikilometer -->
            <b-col>
                <b-input-group prepend="Freikilometer" append="Km" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-km-free"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].in_km_frei"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Gesamtkilometer -->
            <b-col>
                <b-input-group prepend="Gesamtkilometer" append="Km" style="padding: 10px; border-bottom: 3px dashed #bbb;">
                    <b-form-input disabled
                        id="input-in-km-free"
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKilometerInner"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Preis / Km -->
            <b-col>
                <b-input-group prepend="Km-Preis" append="€/Km" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-km-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].in_km_preis"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Gesamt Kilometerpreis -->
            <b-col>
                <b-input-group prepend="Gesamt Km-Preis" append="€" style="padding: 10px;">
                    <b-form-input disabled
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKiloPreisInner"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Pauschalpreis -->
            <b-col>
                <b-input-group prepend="Pauschale" append="€" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-pa-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].in_pa_preis"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col><!-- Gesamtpreis -->
                <b-input-group prepend="Gesamtpreis" append="€" style="padding: 10px; border-top: 3px solid #bbb;">
                    <b-form-input disabled
                        id="input-in-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="priceIn"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <div 
            class="attention-text"
            v-if="validateInnerorts"
            >Achtung: Nicht genug Informationen, bitte das Feld "EO -> ZO (km)" ausfüllen.</div>
        </b-form-row>
        </b-col>

        <!-- ####################### Außerorts ####################### -->

        <b-col cols="6">
        <h2 class="text-left">Außerorts</h2>
        <b-form-row><!-- Kilometer -->
            <b-col>
                <b-input-group prepend="Kilometer" append="Km" style="padding: 10px;">
                    <b-form-input disabled
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKilometerAllOuter"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Freikilometer -->
            <b-col>
                <b-input-group prepend="Freikilometer" append="Km" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-km-free"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].out_km_frei"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Gesamtkilometer -->
            <b-col>
                <b-input-group prepend="Gesamtkilometer" append="Km" style="padding: 10px; border-bottom: 3px dashed #bbb;">
                    <b-form-input disabled
                        id="input-in-km-free"
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKilometerOuter"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Preis / Km -->
            <b-col>
                <b-input-group prepend="Km-Preis" append="€/Km" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-km-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].out_km_preis"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Gesamt Kilometerpreis -->
            <b-col>
                <b-input-group prepend="Gesamt Km-Preis" append="€" style="padding: 10px;">
                    <b-form-input disabled
                        class="nbrFieldRO"
                        type="number"
                        v-model="getKiloPreisOuter"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row><!-- Pauschalpreis -->
            <b-col>
                <b-input-group prepend="Pauschale" append="€" style="padding: 10px;">
                    <b-form-input disabled
                        id="input-in-pa-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="form.tarife[form.selectedTarif].out_pa_preis"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col><!-- Gesamtpreis -->
                <b-input-group prepend="Gesamtpreis" append="€" style="padding: 10px; border-top: 3px solid #bbb;">
                    <b-form-input disabled
                        id="input-in-preis"
                        class="nbrFieldRO"
                        type="number"
                        v-model="priceOut"
                        required
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <div 
            class="attention-text"
            v-if="validateAußerorts"
            >Achtung: Nicht genug Informationen, bitte <u>alle</u> Felder ausfüllen.</div>
        </b-form-row>
        </b-col>
        </b-form-row>
    </span>
    </b-form>
</div>
</template>

<script>
import data from '../../data/data.js'
export default {
    data: function(){
            return {
                form: {
                    'eo': "",
                    'soeo': 0,
                    'eozo': 0,
                    'zoso': 0,
                    'tarifliste': [
                        { value: 'null', text: 'Bitte Tarif wählen' },
                    ],
                    'selectedTarif': 'null',
                    'tarife': {
                        'null': {
                            tarif: '',
                            in_km_preis: 0,
                            in_pa_preis: 0,
                            in_km_frei: 0,
                            out_km_preis: 0,
                            out_pa_preis: 0,
                            out_km_frei: 0
                        },
                    },
                    'in_km_total': 0,

                    'out_km_total': 0
                }
            }
        },
    /**
     * Loads all tarife from server
     */
    beforeMount(){
        data.getAllFromServer('tarif')
            .then((res)=>{
                for(const tarif of res.data){
                    this.form.tarifliste.push({value: tarif.tarif, text: tarif.tarif})
                    this.form.tarife[tarif.tarif] = tarif
                }
            })
    },    
    computed: {
        /** Calculates the price in the city, based on km driven and price per km. */
        priceIn() {
            return Math.round((this.getKiloPreisInner + this.form.tarife[this.form.selectedTarif].in_pa_preis + Number.EPSILON) *100) / 100
        },
        priceOut() {
            return Math.round((this.getKiloPreisOuter + this.form.tarife[this.form.selectedTarif].out_pa_preis + Number.EPSILON) *100) / 100
        },
        priceTotal() {
            return Math.round((this.form.in_km_total * this.form.tarife[this.form.selectedTarif].in_km_preis
                + this.form.out_km_total * this.form.tarife[this.form.selectedTarif].out_km_preis + Number.EPSILON) *100 ) /100
        },
        getKilometerAllInner(){
            return this.form.eozo
        },
        getKilometerInner(){
            return (this.form.eozo - this.form.tarife[this.form.selectedTarif].in_km_frei > 0) ? this.form.eozo - this.form.tarife[this.form.selectedTarif].in_km_frei : 0
        },
        getKilometerAllOuter(){
            return parseFloat(this.form.eozo) + parseFloat(this.form.soeo) + parseFloat(this.form.zoso)
        },
        getKilometerOuter(){
            return ((parseFloat(this.form.eozo) + parseFloat(this.form.soeo) + parseFloat(this.form.zoso)) - this.form.tarife[this.form.selectedTarif].out_km_frei > 0) ? (parseFloat(this.form.eozo) + parseFloat(this.form.soeo) + parseFloat(this.form.zoso))  - this.form.tarife[this.form.selectedTarif].out_km_frei : 0
        },
        getKiloPreisInner(){
            return Math.round((this.getKilometerInner * this.form.tarife[this.form.selectedTarif].in_km_preis + Number.EPSILON) * 100) / 100
        },
        getKiloPreisOuter(){
            return Math.round((this.getKilometerOuter * this.form.tarife[this.form.selectedTarif].out_km_preis + Number.EPSILON) * 100) / 100
        },
        validateInnerorts(){
            return !(this.form.eozo > 0)
        },
        validateAußerorts(){
            return !(this.form.eozo > 0 && this.form.soeo > 0 && this.form.zoso > 0)
        },
    }
}
</script>

<style>

.attention-text{
    color: red;
    font-size: 16px;
}

.submit{
    margin: 10px;
}

/** 
 * Input fields of type number which only display information.
 * 
 * Mozilla specific:
 *  -moz-apperance
 *
 * Chrome, Safari, Edge, Opera:
 *  -webkit-appearance: none;
 *  -margin: 0;
 */
.nbrFieldRO {
    -moz-appearance: textfield;
    text-align: right;
}

/** Input fields of type number which are editable. */
.nbrFieldEA {
    text-align: right;
}

.unit {
    left: -10px;
}

</style>