<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="success">
            <b-navbar-brand href="/">Meldesystem Gorris</b-navbar-brand>
                <b-navbar-nav class="d-none d-lg-flex" v-if="$store.getters.isLoggedIn">
                    <div class="h2 mb-0">
                    <b-nav-item to="/mailservice"><b-icon-envelope></b-icon-envelope><small> Mail Service</small></b-nav-item>
                    </div>
                    <div class="h2 mb-0">
                    <b-nav-item to="/rechnerservice"><b-icon-calculator></b-icon-calculator><small>Fahrtenrechner</small></b-nav-item>
                    </div>             
                    <div class="h2 mb-0">
                    <b-nav-item v-if="$store.getters.getRole === 'sysadmin' || $store.getters.getRole=== 'admin'" to="/einstellungen"><b-icon-gear></b-icon-gear><small>Einstellungen</small></b-nav-item>
                    </div>
                </b-navbar-nav>
                <b-nav-item-dropdown v-if="$store.getters.isLoggedIn" class="d-flex d-lg-none navbar-burger" text="Navigation" right>
                    <b-dropdown-item to="/mailservice">Mail Service</b-dropdown-item>
                    <b-dropdown-item to="/rechnerservice">Fahrtenrechner</b-dropdown-item>
                    <b-dropdown-item v-if="$store.getters.getRole === 'sysadmin' || $store.getters.getRole=== 'admin'" to="/einstellungen">Einstellungen</b-dropdown-item>
                    <b-dropdown-item @click="logout"><span class="logout-link">Logout</span> {{$store.getters.getUsername}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="$store.getters.isLoggedIn" class="d-none d-lg-flex navbar-burger" :text="$store.getters.getUsername" right>
                    <b-dropdown-item @click="logout">
                    <span class="logout-link">Logout</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
        </b-navbar>
    </div>
</template>

<script>
import data from '../data/data'
export default {
    methods:{
        logout: function(){
            data.logout()
                .then(()=>{
                    this.$router.push('/login')
                    this.$store.commit("setUsername", '')
                    this.$store.commit("setLoggedInStatus", false)
                })
        }
    }
}
</script>

<style>

.logout-link{
    color: red;
}

.nav-item{
    margin: 0 20px 0 20px;
    color: rgb(243, 243, 243);
    padding: 0 0 0 0;
}
.nav-item:hover{
    margin: 0 20px 0 20px;
    color: rgb(0, 128, 0);
}
.navbar-burger a{
    color: white;
}

.navbar-burger a:hover{
    color: whitesmoke;
}

.navbar-burger li a{
    color: rgb(0, 128, 0);
}
.navbar-burger li a:hover{
    color: black;
}

</style>