<template>
<div>
    <h1>{{title}}</h1>
    <b-form>
        <!-- Standort -->
        <h2 class="text-left">Allgemeine Angaben</h2>
        <b-form-row class="justify-content-center">
            <b-col cols="6">
                <b-form-group
                    id="input-group-standort"
                >
                    <b-form-select :state="validateLocation" v-model="form.selectedStandort" :options="form.standorte"></b-form-select>
                </b-form-group>
            </b-col>
        </b-form-row>
        <!-- Personendaten -->
        <h2 class="text-left">Personendaten</h2>

        <!-- Name -->
        <b-form-row class="justify-content-center">
            <b-col cols="6">
                <b-form-group>
                    <b-form-input
                        id="input-0"
                        v-model="form.name"
                        type="text"
                        placeholder="Deinen Namen eintragen"
                        required
                        :state="validateName"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>
        
        <!-- Gemeldeter -->
        <b-form-row class="justify-content-center" v-show="form.fields.showReported">
            <b-col cols="6">
                <b-form-group>
                    <b-form-input
                        id="input-1"
                        v-model="form.reported"
                        type="text"
                        placeholder="Um wen geht es?"
                        :state="validateReported"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- Krankmeldung -->
        <h2 class="text-left" v-show="form.fields.showDates">Daten zur Krankmeldung</h2>   
        <b-form-row>
            <!-- Krankheits Firstdate -->
            <b-col cols="6">
                <b-form-group
                    label-cols="2"
                    content-cols="10"
                    label="Von"
                    label-for="datepicker-start-date"
                    v-show="form.fields.showDates"
                >
                    <b-form-datepicker
                        id="datepicker-start-date"
                        v-model="form.startdate"
                        placeholder="Erster Krankheitstag"
                        start-weekday="1"
                        locale="de"
                        :state="validateDateStart"
                        v-show="form.fields.showDates"
                    ></b-form-datepicker>
                </b-form-group>
            </b-col>

            <!-- Krankheits Endate -->
            <b-col cols="6">
                <b-form-group
                    label-cols="2"
                    content-cols="10"
                    label="bis"
                    label-for="datepicker-end-date"
                    v-show="form.fields.showDates"
                >
                    <b-form-datepicker
                        id="datepicker-end-date"
                        v-model="form.enddate"
                        placeholder="Letzter Krankheitstag"
                        start-weekday="1"
                        locale="de"
                        :state="validateDateEnd"
                        v-show="form.fields.showDates"
                    ></b-form-datepicker>
                </b-form-group>
            </b-col>
        </b-form-row>
        
        <!-- Fahrzeug Select -->
        <h2 v-show="form.fields.showVehicles && form.selectedStandort" class="text-left">Fahrzeug wählen</h2>
        <b-form-row class="justify-content-center">
            <b-col cols="6">
                <b-form-group>
                    <b-form-input
                    v-model="form.selectedFahrzeug" 
                    style="margin-bottom: 10px;"
                    :state="validateFahrzeug"
                    v-show="form.fields.showVehicles && form.selectedStandort"
                    list="vehicleOptions"
                    placeholder="Fahrzeug eintragen"
                    >
                    </b-form-input>
                    <datalist id="vehicleOptions">
                        <option v-for="fahrzeug in getSelectedFahrzeuge" :key="fahrzeug._id">{{ fahrzeug }}</option>
                    </datalist>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- Telefon -->
        <b-form-row class="justify-content-center">
            <b-col cols="6">
                <b-form-group>
                    <b-form-input
                        id="input-2"
                        v-model="form.telNumber"
                        type="text"
                        placeholder="Telefonnr. für den Rückruf"
                        v-show="form.fields.showtelNumber"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- Geschäftsführer -->
        <b-form-row class="justify-content-center" v-show="form.fields.showCeos">
            <h4 class="text-left" v-show="form.selectedStandort">Bitte Geschäftsführer wählen:</h4>
            <b-col cols="6">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        v-model="form.selectedCeo"
                        name="ceo-radio"
                        :aria-describedby="ariaDescribedby"
                    >
                        <b-form-radio
                        v-for="(ceo, index) of getCeos" :key="index"
                        :value="ceo.mail"
                        >{{ceo.name}}</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- Freitext -->
        <b-form-row class="justify-content-center">
            <b-col cols="12">
                <b-form-group>
                    <b-form-textarea
                        id="textarea-rows"
                        placeholder="Beschreibung des Sachverhaltes..."
                        rows="8"
                        v-model="form.text"
                        :state="validateText"
                    ></b-form-textarea>  
                </b-form-group>
            </b-col>
        </b-form-row>
        
        <!-- Submit -->
        <b-row align-h="end">
            <b-col cols="auto">
                <b-button
                class="submit" 
                type="button" 
                :variant="toggleButtonVariant" 
                @click="submit"
                :disabled="!validateForm"
                >
                Abschicken
                </b-button>
            </b-col>
        </b-row>
    </b-form>
    <MailSpinner 
    ref="spinner" 
    :textFields.sync="mailToSend"
    @submitSuccess="clearForm"
    />
</div>
</template>

<script>
import data from '../../data/data.js'
import MailSpinner from "../spinner/MailSpinner.vue"
export default {
    components: {
        MailSpinner
    },
    data: function(){
        return {
            id: "",
            title: "",
            mailgrund: "",
            form: {
                'name': "",
                'reported': "",
                'text': "",
                'telNumber': "",
                'startdate': "",
                'enddate': "",
                'fahrzeuge': [],
                'standorte': [
                    { value: null, text: 'Bitte Standort wählen' },
                ],
                'selectedStandort': null,
                'selectedFahrzeug': null,
                'fields': {
                    showDates: false,
                    showtelNumber: false,
                    showVehicles: false,
                    showReported: false,
                    showCeos: false,
                },
                ceos: {},
                selectedCeo: ''
            },
            mailToSend: {}
        }
    },
    methods:{
        /**
         * Creates Mailobject to be sent and passes the information to the spinner object, which executes the requests
         */
        submit: function(){
            // Create Mail to be sent
            this.mailToSend.mailgrund = this.mailgrund
            this.mailToSend.senderName = this.form.name
            this.mailToSend.reported = this.form.reported
            this.mailToSend.subject = this.title
            this.mailToSend.text = this.form.text
            this.mailToSend.telNumber = this.form.telNumber
            this.mailToSend.standort = this.form.selectedStandort
            this.mailToSend.sickStartDate = this.form.startdate
            this.mailToSend.sickEndDate = this.form.enddate
            this.mailToSend.fahrzeug = this.form.selectedFahrzeug
            this.mailToSend.ceo = this.form.selectedCeo
            this.$refs.spinner.submit()
        },
        /**
         * Clears the form after a successful request
         * Form keeps its content, if request fails
         */
        clearForm: function(){
            this.form.name = ""
            this.form.reported = ""
            this.form.text = ""
            this.form.telNumber = ""
            this.form.startdate = ""
            this.form.enddate = ""
            this.form.selectedStandort = null
            this.form.selectedFahrzeug = null
            this.form.selectedCeo = ''
        },
        /**
         * Gets alls CEOS from server in case of Mail an die Geschäftsführung
         */
        setCeos: function(){
            data.getAllFromServer('ceo')
            .then((res)=>{
                for(const kontakt of res.data){
                    if(!this.form.ceos[kontakt.standort.name]) this.form.ceos[kontakt.standort.name] = []
                    this.form.ceos[kontakt.standort.name].push({
                            name: kontakt.name,
                            mail: kontakt.mail
                    })
                }
            })
        }
    },
    computed: {
        /**
         * Validates the start date of the sickness, if present
         */
        validateDateStart(){
            return this.form.startdate.length > 0
        },
        /**
         * Validates the end date of the sickness, if present
         */
        validateDateEnd(){
            return this.form.enddate.length > 0
        },
        /**
         * Validates the name of the sender
         */
        validateName(){
            return this.form.name.length > 0
        },
        /**
         * Validates the email of the sender
         */
        validateReported(){
            return this.form.reported.length > 0
        },
        /**
         * Validates, if a location was choosen by the sender
         */
        validateLocation(){
            return this.form.selectedStandort != null
        },
        /**
         * Validate if fahrzeug is chosen correctly
         */
        validateFahrzeug(){
            return this.getSelectedFahrzeuge.some(el => el === this.form.selectedFahrzeug)
        },
        /**
         * Validates, if text was entered 
         */
        validateText(){
            return this.form.text.length > 0
        },
        /**
         * Validates the whole form by checking all the other validation-functions
         */
        validateForm(){
            return (
                this.validateName && this.validateLocation && this.validateText 
                && (this.validateDateStart || !this.form.fields.showDates)
                && (this.validateDateEnd || !this.form.fields.showDates)
                && (this.validateDateEnd || !this.form.fields.showDates)
                && (this.validateDateEnd || !this.form.fields.showDates)
                && (this.validateReported || !this.form.fields.showReported)
                && (this.validateFahrzeug || !this.form.fields.showVehicles)
            )
        },
        /**
         * Toggles the button to green when the form is valid, grey otherwise
         */
        toggleButtonVariant(){
            return this.validateForm ? "success" : "outline-secondary"
        },
        /**
         * Returns ceos of the selected standort
         */
        getCeos(){
            return this.form.ceos[this.form.selectedStandort]
        },
        /**
         * Returns the fahrzeuge to the selected Standort
         */
        getSelectedFahrzeuge(){
            return this.form.fahrzeuge
                .filter(el => el.standort.name === this.form.selectedStandort)
                .map(el => el.nummer)
        }
    },
    /**
     * Loads all relevant data from the server
     * EG Standorte and Fahrzeuge 
    */
    beforeMount(){
        // Get all Standorte before mounting
        data.getAllFromServer('standort')
            .then((res)=>{
                for(const standort of res.data){
                   this.form.standorte.push({ value: standort.name, text: standort.name }) 
                }                
            })
            .catch((err)=>{
                this.form.standorte.push({ value: 'Fehler', text: `Ein Fehler beim laden ist aufgetreten...${err}` })
            })

        // Get all Fahrzeuge after mounting
        data.getAllFromServer('fahrzeug')
            .then(res=>{
                this.form.fahrzeuge = res.data
            })

        // Properly name the form depending on id and show/hide input
        // Maps the title to the Mailgrund in the database
        this.id = this.$route.params.id
        switch (this.id) {
            case "krankmeldung":
                this.title = "Krankmeldung"
                this.mailgrund = "Krankmeldung"
                this.form.fields.showDates = true
                this.form.fields.showReported = true
                break;
            case "medizinprodukte":
                this.title = "Medizinprodukte"
                this.mailgrund = "Medizinprodukte"
                this.form.fields.showVehicles = true
                this.form.fields.showReported = true
                break;
            case "ceo-meldung":
                this.title = "Mail an die Geschäftsführung"
                this.mailgrund = 'Mail an Geschäftsführung'
                this.form.fields.showtelNumber = true
                this.form.fields.showCeos = true
                this.setCeos()
                break;
            case "infobox":
                this.title = "Infobox"
                this.mailgrund = "Infobox"
                break;
            case "beschwerde":
                this.title = "Beschwerde einreichen"
                this.mailgrund = "Beschwerde"
                this.form.fields.showtelNumber = true
                break;
            case "infektion":
                this.title = "Infektion/Desinfektion melden"
                this.mailgrund = "Infektion/Desinfektion"
                this.form.fields.showVehicles = true
                break;
            case "schadenmeldung":
                this.title = "Schaden melden"
                this.mailgrund = "Fahrzeugschaden"
                this.form.fields.showVehicles = true
                this.form.fields.showReported = true
                break;
            default:
                break;
        }
    },
}
</script>

<style>

.submit{
    margin: 10px;
}

</style>