<template>
  <div id="app">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap" rel="stylesheet"> 
    <TopNavi/>
    <b-container fluid>
      <b-row>
        <b-col>
          <Content/>
        </b-col>
      </b-row>
    </b-container>    
  </div>
</template>

<script>
import TopNavi from './components/TopNavi.vue'
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    TopNavi,
    Content
  },
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

#app #content{
  margin-bottom: 30px;
}

#app .sidenavi{
  margin: 10px 10px 0 0;
  min-width: 50px;
}
#app .container{
  float: left;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
