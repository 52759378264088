<template>
    <div id="content">
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
    </div>
</template>

<script>
export default {

}
</script>

<style>

#content{
    background-color: rgb(211, 211, 211);
    margin: 10px 0 0 20px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 5px 5px 5px darkgray;
}

</style>